<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <div class="search-bar">
          <b-form-input
            placeholder="Search..."
            v-model="filters.search"
            @input="onFilterChange"
          />
        </div>
        <div class="category">
          <v-select
            v-model="filters.category"
            :reduce="(option) => option.label"
            placeholder="Select Category"
            :options="
              categories.map((category) => ({
                value: category.id,
                label: category.name,
              }))
            "
            @input="onFilterChange"
          >
          </v-select>
        </div>
        <div class="w-25 ml-2" v-if="user_type === 'counselor'">
          <v-select
            v-model="filters.franchise_id"
            placeholder="Select Franchise"
            :options="
              fe_franchises.map((franchise) => ({
                value: franchise.user_id,
                label: `${franchise.user_firstname} ${franchise.user_lastname}`,
              }))
            "
            :clearable="true"
            item-text="label"
            :reduce="(item) => item.value"
            @input="onFilterChange"
            class="per-page-selector d-inline-block w-100"
          />
        </div>
        <div class="d-flex justify-content-end button">
          <b-button class="btn-btn" variant="primary" @click="addEvent">{{
            user_type === "counselor" ? "Add Event" : "Add My Event"
          }}</b-button>
        </div>
      </div>
    </b-card>

    <!-- Add event form  -->
    <b-modal
      id="modal-lg"
      size="lg"
      centered
      title="Event"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <div class="form_elements">
          <b-row class="mx-1">
            <b-col cols="12">
              <div>
                <div class="mb-3 d-flex justify-content-center">
                  <img
                    id="selectedImage"
                    :src="
                      form_data.fe_thumbnail
                        ? FILESURL + form_data.fe_thumbnail
                        : require('@/assets/images/banner/upgrade.png')
                    "
                    style="width: 200px; height: 200px"
                  />
                </div>

                <ImageUploadWithCropper
                  v-model="form_data.fe_thumbnail"
                  :width="800"
                  :height="450"
                  validatortype="image"
                  imagetype="brand"
                  label="Upload Brand Logo"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="mx-1" v-if="user_type === 'counselor'">
            <b-col cols="6">
              <v-select
                v-model="franchise_id"
                :options="
                  fe_franchises.map((franchise) => ({
                    value: franchise.user_id,
                    label: `${franchise.user_firstname} ${franchise.user_lastname}`,
                  }))
                "
                :clearable="true"
                item-text="label"
                :reduce="(item) => item.value"
                class="per-page-selector d-inline-block w-100"
                placeholder="Select Franchise"
                :disabled="form_data.fe_type === 'corporate'"
                v-if="user_type === 'counselor'"
              />
            </b-col>
            <b-col cols="6">
              <v-select
                v-model="form_data.fe_type"
                :options="[
                  { value: 'corporate', label: 'Corporate' },
                  { value: 'franchise', label: 'Franchise' },
                ]"
                :clearable="true"
                item-text="label"
                :reduce="(item) => item.value"
                class="per-page-selector d-inline-block w-100"
                :disabled="user_type === 'franchise'"
                placeholder="Select Type"
              />
            </b-col>
          </b-row>
          <b-row class="mx-1">
            <b-col cols="6">
              <b-form-input placeholder="Name" v-model="form_data.fe_name">
              </b-form-input>
            </b-col>
            <b-col cols="6">
                <v-select
                  v-model="form_data.fe_mode"
                  :options="[
                    { value: 'online', label: 'Online' },
                    { value: 'offline', label: 'Offline' },
                  ]"
                  :clearable="true"
                  item-text="label"
                  :reduce="(item) => item.value"
                  class="per-page-selector d-inline-block w-100"
                  placeholder="Select Mode"
                />
            </b-col>
          </b-row>

          <b-row class="mx-1">
            <b-col cols="12">
              <b-form-input placeholder="Venue" v-model="form_data.fe_venue" v-if="form_data.fe_mode === 'offline'">
              </b-form-input>
              <b-form-input placeholder="Link" v-model="form_data.fe_link" v-if="form_data.fe_mode === 'online'">
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mx-1">
            <b-col cols="12">
              <!-- <b-form-textarea
                id="textarea"
                placeholder="Description"
                v-model="form_data.fe_description"
              > -->
              <quill-editor
                id="quil-content"
                :options="editorOption"
                v-model="form_data.fe_description"
              />
              <!-- </b-form-textarea> -->
            </b-col>
          </b-row>
          <b-row class="mx-1">
            <b-col cols="12">
              <v-select
                v-model="form_data.fe_category"
                placeholder="Select Category"
                :options="categories.map((category) => category.name)"
              >
              </v-select>
            </b-col>
          </b-row>
          <b-row class="mx-1">
            <b-col cols="12">
              <div class="d-flex">
                <flat-pickr
                  v-model="form_data.fe_start_date"
                  class="form-control mr-1"
                  placeholder="Date"
                  name="date"
                />
                <flat-pickr
                  v-model="form_data.fe_time"
                  class="form-control"
                  placeholder="Time"
                  name="time"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                  }"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="outline-success" @click="save">
          <span class="save_button_text">Save</span>
        </b-button>
      </template>
    </b-modal>
    <div>
      <b-card>
        <b-tabs
          pills
          nav-class="d-flex justify-content-center mt-2"
          v-model="active_tab_index"
        >
          <b-tab @click="onChange('corporate')">
            <template #title>
              <span style="font-size: larger">
                {{ user_type === "counselor" ? "My Events" : "Corporate" }}
              </span>
            </template>

            <b-nav tabs class="mt-2">
              <b-nav-item
                :active="filters.period === 'upcoming'"
                @click="onPeriodChange('upcoming')"
                >Upcoming</b-nav-item
              >
              <b-nav-item
                :active="filters.period === 'past'"
                @click="onPeriodChange('past')"
                >Past</b-nav-item
              >
              <b-nav-item
                :active="filters.period === null"
                @click="onPeriodChange(null)"
                >All</b-nav-item
              >
            </b-nav>
            <b-card class="bg-white p-2">
              <div class="video-grid">
                <div
                  v-for="(event, t_index) in events"
                  :key="'event_' + t_index"
                  md="6"
                  lg="3"
                  class="d-flex mb-3 shadow-lg mr-2 rounded-lg"
                >
                <b-card
                    no-body
                    class="overflow-hidden w-100"
                    style="cursor: pointer"
                    @click="goToEvent(event.fe_id)"
                  >
                    <div class="position-relative" style="padding-top: 56.25%">
                      <b-img
                        :src="FILESURL + event.fe_thumbnail"
                        :alt="event.fe_name"
                        class="position-absolute"
                        style="
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                        "
                      />
                      <div>
                        <b-badge variant="primary" class="badge-glow">
                          {{ event.fe_category }}
                        </b-badge>
                      </div>
                    </div>

                    <b-card-body class="d-flex flex-column position-relative">
                      <b-card-title>{{ event.fe_name }}</b-card-title>
                      <b-card-text
                        class="flex-grow-1 mb-2 description"
                        v-html="event.fe_description"
                      ></b-card-text>
                      <b-card-text class="flex-grow-1">
                        <div class="mb-2">
                          <feather-icon
                            icon="MapPinIcon"
                            size="25"
                            stroke="#333"
                          />
                          <span
                            class="ml-1 font-weight-bold"
                            style="font-size: 1.2rem"
                            >{{ event.fe_venue === null || event.fe_venue === "" ? "Online" : event.fe_venue }}</span
                          >
                          <span class="ml-1" v-if="event.fe_mode === 'online'">
                            <a :href="event.fe_link"  style="text-decoration: underline; font-size: medium;" @click.stop target="_blank">Join</a>
                          </span>
                        </div>
                      </b-card-text>
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="CalendarIcon"
                          size="25"
                          stroke="#333"
                        />
                        <b-card-text
                          class="ml-1 font-weight-bold"
                          style="font-size: 1.2rem"
                        >
                          {{
                            new Date(event.fe_start_date).toLocaleDateString(
                              "en-US",
                              { month: "long", day: "numeric" }
                            )
                          }},<span class="font-weight-bold">
                            <!-- <feather-icon icon="ClockIcon" size="25" /> -->

                            <span class=""> {{ event.fe_time }}</span></span
                          >
                        </b-card-text>
                      </div>
                      <div class="edit">
                        <feather-icon
                          icon="EditIcon"
                          size="25"
                          @click.prevent.stop="edit(event)"
                        ></feather-icon>
                      </div>
                      <div class="pt-2" v-if="user_type === 'counselor'">
                        <b-badge variant="success" class="badge-glow-name">
                          {{ event.fullname }}
                        </b-badge>
                      </div>
                    </b-card-body>
                  </b-card>
                </div>
              </div>
            </b-card>
            <h2 class="text-center mt-2 mb-2" v-if="events.length == 0">
              No Events Available
            </h2>
          </b-tab>
          <b-tab @click="onChange('franchise')">
            <template #title>
              <span style="font-size: larger">
                {{ user_type === "franchise" ? "My Events" : "Franchise" }}
              </span>
            </template>
            <b-nav tabs class="mt-2">
              <b-nav-item
                :active="filters.period === 'upcoming'"
                @click="onPeriodChange('upcoming')"
                >Upcoming</b-nav-item
              >
              <b-nav-item
                :active="filters.period === 'past'"
                @click="onPeriodChange('past')"
                >Past</b-nav-item
              >
              <b-nav-item
                :active="filters.period === null"
                @click="onPeriodChange(null)"
                >All</b-nav-item
              >
            </b-nav>
            <b-card class="bg-white p-2">
              <div class="video-grid">
                <div
                  v-for="(event, t_index) in events"
                  :key="'event_' + t_index"
                  md="6"
                  lg="3"
                  class="d-flex mb-3 shadow-lg rounded-lg"
                >
                  <b-card
                    no-body
                    class="overflow-hidden w-100"
                    style="cursor: pointer"
                    @click="goToEvent(event.fe_id)"
                  >
                    <div class="position-relative" style="padding-top: 56.25%">
                      <b-img
                        :src="FILESURL + event.fe_thumbnail"
                        :alt="event.fe_name"
                        class="position-absolute"
                        style="
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                        "
                      />
                      <div>
                        <b-badge variant="primary" class="badge-glow">
                          {{ event.fe_category }}
                        </b-badge>
                      </div>
                    </div>

                    <b-card-body class="d-flex flex-column position-relative">
                      <b-card-title>{{ event.fe_name }}</b-card-title>
                      <b-card-text
                        class="flex-grow-1 mb-2 description"
                        v-html="event.fe_description"
                      ></b-card-text>
                      <b-card-text class="flex-grow-1">
                        <div class="mb-2">
                          <feather-icon
                            icon="MapPinIcon"
                            size="25"
                            stroke="#333"
                          />
                          <span
                            class="ml-1 font-weight-bold"
                            style="font-size: 1.2rem"
                            >{{ event.fe_venue === null || event.fe_venue === "" ? "Online" : event.fe_venue }}</span
                          >
                          <span class="ml-1" v-if="event.fe_mode === 'online'">
                            <a :href="event.fe_link"  style="text-decoration: underline; font-size: medium;" @click.stop target="_blank">Join</a>
                          </span>
                        </div>
                      </b-card-text>
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="CalendarIcon"
                          size="25"
                          stroke="#333"
                        />
                        <b-card-text
                          class="ml-1 font-weight-bold"
                          style="font-size: 1.2rem"
                        >
                          {{
                            new Date(event.fe_start_date).toLocaleDateString(
                              "en-US",
                              { month: "long", day: "numeric" }
                            )
                          }},<span class="font-weight-bold">
                            <!-- <feather-icon icon="ClockIcon" size="25" /> -->

                            <span class=""> {{ event.fe_time }}</span></span
                          >
                        </b-card-text>
                      </div>
                      <div class="edit">
                        <feather-icon
                          icon="EditIcon"
                          size="25"
                          @click.prevent.stop="edit(event)"
                        ></feather-icon>
                      </div>
                      <div class="pt-2" v-if="user_type === 'counselor'">
                        <b-badge variant="success" class="badge-glow-name">
                          {{ event.fullname }}
                        </b-badge>
                      </div>
                    </b-card-body>
                  </b-card>
                </div>
              </div>

              <h2 class="text-center mt-2" v-if="events.length == 0">
                No Events Available
              </h2>
            </b-card></b-tab
          >
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BTab,
  BTabs,
  BFormSelect,
  BFormSelectOption,
  BCol,
  BRow,
  BCardBody,
  BImg,
  BNav,
  BNavItem,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import { FILESURL, BASEURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { TokenService } from "@/apiServices/storageService";
import vSelect from "vue-select";
import ImageUploadWithCropper from "@/views/components/ImageUploadWithCropper.vue";
import store from "@/store";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
    BFormFile,
    BForm,
    BTab,
    BTabs,
    BFormSelect,
    BFormSelectOption,
    vSelect,
    ImageUploadWithCropper,
    BCol,
    BRow,
    BCardBody,
    BImg,
    BBadge,
    BNav,
    BNavItem,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filters: {
        search: "",
        category: null,
        type: "corporate",
        franchise_id: null,
        period: "upcoming",
      },
      events: [],
      // start_date: null,
      // end_date: null,
      FILESURL,
      BASEURL,
      selectedAnswer: null,
      trainingIndex: null,
      questionIndex: null,
      fe_franchises: [],
      // user_type: null,
      debounce: null,
      form_data: {
        fe_id: null,
        fe_name: "",
        fe_description: "",
        fe_venue: "",
        fe_mode: null,
        fe_start_date: "",
        fe_time: "",
        fe_thumbnail: "",
        is_active: "Y",
        fe_category: null,
        fe_type: "franchise",
        fe_link: "",
      },
      type: "upcoming",
      fe_thumbnail: "",
      categories: [
        { id: 1, name: "College Presentation" },
        { id: 2, name: "Education Fair (Virtual)" },
        { id: 3, name: "Education Fair (Physical)" },
        { id: 4, name: "Society Event" },
        { id: 5, name: "Community Event" },
        { id: 6, name: "Master Class" },
        { id: 7, name: "Others" },
      ],
      active_tab_index: 0,
      franchise_id: null,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link", "image"],
          ],
        },
        placeholder: "",
      },
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  methods: {
    onPeriodChange(period) {
      this.filters.period = period;
      this.onFilterChange();
    },
    onFilterChange() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onChangeFilter();
      }, 1000);
    },
    onChangeFilter() {
      console.log("filter", this.filters);
      this.getEvents(this.filters);
    },

    onChange(type) {
      this.filters.type = type;
      this.getEvents(this.filters);
    },
    async getEvents() {
      try {
        // const payload = { type: type };
        // console.log("user type",store.getters["user/getUserDetails"] );
        let res;
        if (this.user_type === "counselor") {
          res = await CounselorServices.getALlFranchiseEvents(this.filters);
        } else {
          res = await CommonServices.getFranchiseEvents(this.filters);
        }
        this.events = res.data.data.data;
      } catch (error) {
        console.error("Error in getEvents ", error);
      }
    },
    addEvent() {
      this.form_data = {
        fe_id: null,
        fe_name: "",
        fe_description: "",
        fe_venue: "",
        fe_start_date: "",
        fe_time: "",
        fe_thumbnail: "",
        is_active: "Y",
        fe_category: null,
        fe_type: "franchise",
      };
      this.$bvModal.show("modal-lg");
    },
    async uploadImage() {
      try {
        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("fe_thumbnail", this.fe_thumbnail);

        const response = await axios.post(
          `${BASEURL}/common/event-image`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.form_data.fe_thumbnail = response.data.data.file_path;
          console.log(this.form_data);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },

    async save() {
      try {
        // console.log(this.form_data);
        let res;
        if (this.user_type === "counselor") {
          res = await CounselorServices.saveEvents({
            ...this.form_data,
            franchise_id: this.franchise_id,
          });
        } else {
          res = await CommonServices.saveEvents(this.form_data);
        }
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Event Saved Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getEvents();
          this.form_data = {
            fe_id: null,
            fe_name: "",
            fe_description: "",
            fe_venue: "",
            fe_start_date: "",
            fe_time: "",
            fe_thumbnail: "",
            is_active: "Y",
            fe_category: null,
            fe_type: "franchise",
          };

          this.$bvModal.hide("modal-lg");
          this.getEvents();
        }
      } catch (error) {
        console.error("Error in saving event !! ", error);
      }
    },

    async edit(event) {
      try {
        const res = await CommonServices.editEvents(event);
        this.form_data = res.data.data;
        this.franchise_id = res.data.data.user_id;
        this.$bvModal.show("modal-lg");
      } catch (error) {
        console.error("Error in editing event !! ", error);
      }
    },
    async getAllFranchises() {
      try {
        const response = await CommonServices.getAllFranchises();

        if (response.data.status) {
          this.fe_franchises = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    goToEvent(id) {
      this.$router.push({
        name: "EventsDetail",
        query: { e_id: id },
      });
    }
  },
  beforeMount() {
    const { action } = this.$route.query;
    if (action === "events") {
      this.filters.type = "franchise";
      this.getEvents();
      this.active_tab_index = 1;
      this.$router.replace({ query: {} });
    } else {
      this.active_tab_index = 0;
    }

    if (this.user_type && this.user_type === "franchise") {
      this.filters.type = "franchise";
      this.active_tab_index = 1;
    }
  },
  mounted() {
    setTimeout(() => {
      this.getEvents();
      this.getAllFranchises();
    }, 1000);
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
<style scoped>
.form_elements {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.search-bar {
  width: 70%;
}
.button {
  width: 10%;
}
.category {
  width: 20%;
  margin-left: 10px;
}
.btn-btn {
  /* padding: 0 5px; */
}
.save_button_text {
  padding: 10px 10px;
}
.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.video-container {
  /* margin: 10px;  */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  height: 320px;
}

.training-title {
  text-decoration: underline;
}

/* .video-container {
    height: auto;
    width: 70%;
    border-radius: 10px;
  } */

.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.min-height {
  min-height: 60vh;
}

.container {
  display: flex;
  height: 50vh;
  overflow: hidden;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.map-column {
  width: 60%;
}

iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    height: auto;
  }

  .scrollable {
    height: auto;
    overflow-y: visible;
  }

  .map-column {
    width: 100%;
  }
}

.col-lg-4,
.col-md-6 {
  padding: 0 !important;
}
.edit {
  position: absolute;
  right: 15px;
  bottom: 2px;
}
.badge-glow {
  position: absolute;
  top: 10px;
  right: 10px;
}
.badge-glow-name {
  position: absolute;
  top: 15px;
  right: 10px;
}
.description {
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
